.loadDataButton{
    .btn{
        margin-top: 0;
    }
}
.team-points{
    margin-left: 10px;
}

.admin-champ-image{
    max-width: 50%;
    height: auto;
}

.request-status{
    &-Pendiente{
        color:$danger-states-color;
    }
    &-Aceptado{
        color:$success-states-color;
    }
}
.champ-dashboard-link{
    text-decoration: none;
}

.hidden{
    display: none;
}
.point-table__group-register{
    padding-left: 5px;
}
.add-pointer-cursor{
    cursor: pointer;
}
.podium_position{
    padding: 10px 0;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
}
.podium_image{
    min-height: 170px;
    margin-bottom: 25px;
    align-items: center;
    display: flex;
}

.podium-p1{
    background-image: linear-gradient(to top, #ffd700, #ffdc00, #ffe100, #ffe700, #ffec00);
    @media screen and (min-width: 576px){
        margin-bottom: 50px;
    }
}
.podium-p2{
    background-image: linear-gradient(to top, #84898b, #8d9395, #979c9f, #a0a6a9, #aab0b3);
    @media screen and (min-width: 576px){
        margin-top: 50px;
        margin-bottom: 50px;
    }
}
.podium-p3{
    background-image: linear-gradient(to top, #866100, #8a6400, #8e6700, #936a00, #976d00);    margin-bottom: 25px;
    @media screen and (min-width: 576px){
        margin-top: 50px;
        margin-bottom: 50px;
    }
}