.navbar{
  .navbar-nav{
    .nav-link{
      i.fa + p,
      i.nc-icon + p{
        margin-left: 7px;
      }
    }
  }
}
.header-logo-image{
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 768px){
  .auth-nav-bar{
    margin: 1rem auto 0;
  }
}
