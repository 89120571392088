.alert{
  .close{
    font-size: 1.8rem;
    font-weight: 300;
  }
}

.higest-z-index{
  position: fixed;
  z-index: 1000000;
}
